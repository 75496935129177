import { render, staticRenderFns } from "./EditBlocks.vue?vue&type=template&id=630fe440&scoped=true&"
import script from "./EditBlocks.vue?vue&type=script&lang=js&"
export * from "./EditBlocks.vue?vue&type=script&lang=js&"
import style0 from "./EditBlocks.vue?vue&type=style&index=0&id=630fe440&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../app/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "630fe440",
  null
  
)

export default component.exports